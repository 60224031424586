
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-drop-down {
  font-size: 16px;
  font-family: BrandonText, Lato, sans-serif;
}


.invalid-select {
  flex: 1;
  outline: none;
}

.invalid-select > * {
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 12.5px;
  color: #333;
  align-items: center;
  width: 100%;
  outline: none;
}

.DateInput {
  width: 100%;
  border-bottom: 1px solid #cecece !important;
}

.DateInput_input {
  width: 100%;
}


.alternate-row > :nth-child(1) {
  width: 20vw;
}