@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'BrandonText-Bold';
  src: url('./fonts/BrandonText-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'BrandonText-Black';
  src: url('./fonts/BrandonText-Black.otf') format('opentype');
}
@font-face {
  font-family: 'BrandonText-Medium';
  src: url('./fonts/BrandonText-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'BrandonText-Regular';
  src: url('./fonts/BrandonText-Regular.otf') format('opentype');
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

html {
  font-size: 16px;
  font-size: 60%;
  font-family: 'Montserrat', sans-serif !important;
}

* {
  font-family: 'Montserrat', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  display: flex;
  overflow-y: hidden;
}

#modal-root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.modal-container {
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: stretch;
}

input.visit-input {
  font-family: 'Montserrat' sans-serif !important;  
}

.OT_publisher {
  width: 264px !important;
  height: 198px !important;
  position: absolute !important;
  z-index: 999 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.OT_subscriber {
  position: unset !important;
}

.lp-copy-sel__option,
.lp-copy-sel__single-value {
  white-space: pre-wrap !important;
  font-size: 10px !important;
}

.lp-copy-sel__option:first-line {
  font-size: 15px;
}
.lp-copy-sel__single-value:first-line {
  font-size: 15px;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}


.ant-select-selector, .ant-input, .ant-picker, .ant-select-selection-item, textarea {
  border-radius: 5px !important;
  border: 1px solid #E3E3E3 !important;
  font-weight: 600 !important;
  outline: none;
  font-family: "Montserrat", sans-serif !important;
}

.ant-modal-content, .ant-select > *, textarea {
  font-family: "Montserrat", sans-serif !important;
}

.ant-select-selection-item {
  border: none !important;
  color: #444;
}

::-webkit-scrollbar {
  width: 10px;
}

* {
  scrollbar-width: thin !important;
  scrollbar-color: #c6c2e1 #E3E3E3 !important;
}

.map_container {
  width: 100%;
  height: 50vh;
}

.ant-select-disabled > div > span:nth-child(2) {
  color: rgba(0, 0, 0, 0.25);
}

.no-arrows {
  appearance: textfield;
  -moz-appearance: textfield;
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-empty-description {
  font-weight: 500;
  color: #777;
}

.ant-input-affix-wrapper > input {
  border: none !important;
}